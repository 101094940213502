<nav  #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href=" #" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href=" #" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img src="assets/images/p-logo.png" alt="" />
        <span class="logo-name">PRAXUS</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!--  #END # Full Screen Button -->
        <!-- li class="nav-item" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li -->
        <!--  #START # Notifications-->
        <li class="nav-item" ngbDropdown >

          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons" (click)="markNotificationsRead()">
            <mat-icon  matBadge="{{totalNotificationNumber}}" matBadgePosition="after" matBadgeColor="accent" >notifications_none</mat-icon>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href=" #" *ngFor="let notification of notifications" onClick="return false;">
                    <span *ngIf="notification.notificationType == 'New Activity Added'" [routerLink]="['/student/activity-view']" [queryParams]="{ userId: notification.notificationData.userId, skillId: notification.notificationData.skillId, activityId: notification.notificationData.activityId}" >
                      <span class="table-img msg-user">

                        <span class="fa-stack" *ngIf="notification.activityDetails.type == 'recording' && notification.activityDetails.withACoach == true">
                          <i class="fa-stack-1.75x fa-regular fa-user"  *ngIf="notification.activityDetails.type == 'recording' && notification.activityDetails.withACoach == true" style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="With a Coach"></i>
                          <i class="fa-stack-1x fa-solid fa-microphone-lines"  style="font-size: 12px; color: #5b626b; margin-right: 1%;" title="Recording"></i>
                        </span>
                        <i class="fa-solid fa-people-arrows" *ngIf="notification.activityDetails.type == 'pyi'"  style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Planning Your Interaction"></i>
                        <i class="fa-solid fa-graduation-cap" *ngIf="notification.activityDetails.type == 'eylp'"  style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Establishing Your Learning Path"></i>
                        <i class="fa-solid fa-microphone-lines"  *ngIf="notification.activityDetails.type == 'recording' && (!notification.activityDetails.withACoach || notification.activityDetails.withACoach == false)" style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Recording"></i>
                        <i class="fa-brands fa-youtube" *ngIf="notification.activityDetails.type == 'video'" style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Video" ></i>
                        <i class="fa-solid fa-newspaper" *ngIf="notification.activityDetails.type == 'article'"  style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Article"></i>
                        <i class="fa-solid fa-file-signature" *ngIf="notification.activityDetails.type == 'surveys'"  style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="Survey"></i>
                        <i class="fa-solid fa-chalkboard"  *ngIf="notification.activityDetails.type == 'lms'"  style="font-size: 14px; color: #5b626b; margin-right: 1%;" title="LMS"></i>
                  
              <!--          <i class="fa-brands fa-youtube" *ngIf="notification.activityDetails.type == 'video'" style="font-size: 30px; color: #5b626b; margin-right: 1%;" title="Video" ></i>
                        <i class="fa-solid fa-newspaper" *ngIf="notification.activityDetails.type == 'article'"  style="font-size: 30px; color: #5b626b; margin-right: 1%;" title="Article"></i>
                        <i class="fa-solid fa-file-signature" *ngIf="notification.activityDetails.type == 'surveys'"  style="font-size: 30px; color: #5b626b; margin-right: 1%;" title="Survey"></i>
                        <i class="fa-solid fa-chalkboard"  *ngIf="notification.activityDetails.type == 'lms'"  style="font-size: 30px; color: #5b626b; margin-right: 1%;" title="LMS"></i>
                        <i class="fa-solid fa-microphone-lines"  *ngIf="notification.activityDetails.type == 'recording'" style="font-size: 30px; color: #5b626b; margin-right: 1%;" title="Recording" ></i>
                        <i class="fa-regular fa-user"  *ngIf="notification.activityDetails.type == 'recording' && notification.activityDetails.withACoach == true" style="font-size: 15px; color: #5b626b; margin-right: 1%;" title="With a Coach"></i>

                      -->
                      </span>
                      <span class="menu-info">
                        <span class="menu-title">{{notification.notificationType}}</span>
                        <span class="menu-desc">
                          <i class="material-icons">access_time</i> {{notification.timePassed}}
                        </span>
                        <span class="menu-desc">{{notification.skillDetails.name}}</span>
                        <span class="menu-desc">{{notification.activityName}}</span>

                      </span>
                    </span>

                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1" href=" #" onClick="return false;">Read
              All Notifications</a>
          </div>
        </li>
        <!--  #END # Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>person</mat-icon>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                 <!--   <li>
                      <a href=" #" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href=" #" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">feedback</mat-icon>Feedback
                      </a>
                    </li>
                    <li>
                      <a href=" #" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">help</mat-icon>Help
                      </a>
                    </li> -->
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <button mat-icon-button (click)="reportAnIssue()" class=" nav-notification-icons">
            <mat-icon style="vertical-align: middle;">report_problem</mat-icon>
          </button>

        </li>
        <!--  #END # Tasks -->
        <li class="float-end">
          <!-- button mat-icon-button *ngIf="(authService.currentUserValue?.agencyName == 'Woodlawn County Community Supervision' || authService.currentUserValue?.agencyName == 'Tennessee Department of Correction' )" (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar"-->
            <button mat-icon-button *ngIf="(authService.currentUserValue?.agencyName == 'Woodlawn County Community Supervision')" (click)="toggleRightSidebar()" class="nav-notification-icons js-right-sidebar">
              <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
