<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar" [ngClass]="isOpenSidebar ? 'open' : 'close'"
  (clickOutside)="onClickedOutside($event)">
  <div class="rightSidebarClose" (click)="toggleRightSidebar()"><i class="far fa-times-circle"></i></div>
  <div class="demo-skin"
    [ngStyle]="{'position' : 'relative', 'max-height' : maxHeight+'px', 'max-width' : maxWidth+'px'}"
    [perfectScrollbar]>
    <!-- div class="rightSetting">
      <p>GENERAL SETTINGS</p>
      <ul class="setting-list list-unstyled m-t-20">
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="" checked> Save
                History
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="" checked> Show
                Status
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="" checked> Auto
                Submit Issue
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div class="form-check">
            <div class="form-check m-l-10">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" value="" checked> Show
                Status To All
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div -->

    <!-- button class="m-4 btn ms-auto p-2 bd-highlight" *ngIf="(authService.currentUserValue?.agencyName == 'Woodlawn County Community Supervision' || authService.currentUserValue?.agencyName == 'Tennessee Department of Correction' )" (click)="resetDemoData()" title="Reset Demo Activity" -->
      <button class="m-4 btn ms-auto p-2 bd-highlight" *ngIf="(authService.currentUserValue?.agencyName == 'Woodlawn County Community Supervision')" (click)="resetDemoData()" title="Reset Demo Activity">
        <span class="mat-button-wrapper">
        <button mat-raised-button color="primary" [class.auth-spinner]="loading" [disabled]="loading" class="btn float-end mt-4">Reset Demo Activity</button>
      </span>
    </button>
  </div>

</aside>

<!--  #END # Right Sidebar -->
